import cn from 'classnames';
import Image from 'next/image';
import React from 'react';

import Button from '@components/Button';
import Section from '@components/Section';
import customers from '@data/customers';

const getCustomersByIndexes = (idsArr) => {
  const result = [];

  idsArr.forEach((id) => {
    result.push(customers[id - 1]);
  });

  return result;
};

const Customer = ({ customer }) => {
  return (
    <li className="border md:shadow-features border-gray-200 rounded-[34px] p-7 pb-2">
      <div className="flex items-center gap-4">
        <div>
          <Image
            src={customer.profileImg}
            height="34"
            width="34"
            alt={`${customer.name} profile pic`}
          />
        </div>
        <div>
          <p className="text-base font-inter font-semibold text-black">
            {customer.name}
          </p>
          <span className="text-sm font-inter font-medium text-[#68717A]">
            {customer.title}
          </span>
        </div>
      </div>

      <div>
        <p className="text-gray-700 text-base font-inter font-medium my-6">
          {customer.quote}
        </p>

        {customer.link && (
          <Button
            variant="text"
            href={customer.link}
            hasArrow
            className="inline-flex mb-5"
          >
            Read case study
          </Button>
        )}
      </div>
    </li>
  );
};

type CustomersPropType = {
  col1_ids?: number[];
  col2_ids?: number[];
  col3_ids?: number[];
};

const CustomersSection = ({
  col1_ids = [1, 2, 3],
  col2_ids = [4, 5, 6],
  col3_ids = [7, 8, 9],
}: CustomersPropType) => {
  const data = {
    image: `${process.env.NEXT_PUBLIC_ASSET_HOST}/static/homepage/flaming_heart.png`,
    title: 'Trusted by the best',
    description:
      'Hear firsthand from our esteemed customers about what sets us apart.',
    col1_customers: getCustomersByIndexes(col1_ids),
    col2_customers: getCustomersByIndexes(col2_ids),
    col3_customers: getCustomersByIndexes(col3_ids),
  };

  return (
    <Section className="pt-8 sm:pt-16 lg:pt-32">
      <div className="text-center">
        <Image
          src={data.image}
          height="75"
          width="75"
          alt="PopSQL is trusted by the best"
        />
        <h2 className="text-4xl font-inter font-extrabold my-4">
          {data.title}
        </h2>

        <p
          className={cn(
            'text-base sm:text-xl sm:max-w-md',
            'font-inter font-medium text-gray-600 dark:text-bliss text-center',
          )}
        >
          {data.description}
        </p>
      </div>

      <div className="mt-8 md:mt-24 grid md:grid-cols-3 gap-4 md:gap-8 max-w-[500px] md:max-w-full">
        <ul className="flex flex-col gap-4 md:gap-8">
          {data.col1_customers.map((customer) => (
            <Customer customer={customer} key={customer.id} />
          ))}
        </ul>
        <ul className="flex flex-col gap-4 md:gap-8 md:pt-32">
          {data.col2_customers.map((customer) => (
            <Customer customer={customer} key={customer.id} />
          ))}
        </ul>
        <ul className="flex flex-col gap-4 md:gap-8">
          {data.col3_customers.map((customer) => (
            <Customer customer={customer} key={customer.id} />
          ))}
        </ul>
      </div>
    </Section>
  );
};

export default CustomersSection;
