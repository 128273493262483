import axios from 'axios';

export const submitForm = async (portalId, formGuid, fields) => {
  if (!portalId || !formGuid) {
    console.error('Missing portalId or formId');
    return;
  }

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const hutkMatch = document.cookie.match(/hubspotutk=([^;]*);/);

  const response = await axios.post(
    `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
    {
      portalId,
      formGuid,
      fields,
      context: {
        pageName: document.title,
        pageUri: document.URL,
        hutk: hutkMatch?.[1],
      },
    },
    config,
  );

  return response;
};
