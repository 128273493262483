import React, { useContext } from 'react';

import ROUTES from '../../routes';

import Button from '@components/Button';
import EmailInput from '@components/EmailInput';
import { Context } from '@contexts/AppContext';

type CtaFormPropsType = {
  ctaText?: string;
  onClickFn?: () => void;
};

const CtaForm = ({ ctaText = 'Get started', onClickFn }: CtaFormPropsType) => {
  const { isLoggedIn } = useContext(Context);

  return (
    <>
      {isLoggedIn ? (
        <div className="my-2 md:my-4 flex flex-col gap-4 text-center max-w-[300px] z-10">
          <Button
            href={ROUTES.appPopSQL}
            className="min-w-[260px]"
            variant="primary"
            hasArrow
          >
            Open app
          </Button>
          <p className="font-inter text-gray-600 dark:text-cobalt-600 font-medium">
            Or get the{' '}
            <a
              className="text-black font-semibold underline underline-offset-4 decoration-pink hover:decoration-pink/50 dark:text-blue-100 dark:no-underline dark:hover:text-blue-200"
              href={ROUTES.download}
            >
              desktop version
            </a>
          </p>
        </div>
      ) : (
        <EmailInput
          ctaText={ctaText}
          onClickButton={onClickFn}
          className="motion-safe:opacity-0 motion-safe:animate-fadeInFromTop motion-safe:animation-delay-[350ms]"
        />
      )}
    </>
  );
};

export default CtaForm;
