import cn from 'classnames';
import Image from 'next/image';
import React from 'react';

import Heading from '../Heading';
import Section from '../Section';

import CtaForm from '@components/common/CtaForm';
import { loaderProp } from '@utils/random';

type HeroBottomProps = {
  bg?: string;
  ctaText?: string;
  description?: string;
  icon?: string;
  spacing?: string;
  title?: string;
};

const HeroBottom = ({
  title = 'Empowering data teams',
  description = `PopSQL is the SQL editor for streamlined teamwork and insightful data
collaboration.`,
  ctaText = 'Get started',
  icon,
  bg = `${process.env.NEXT_PUBLIC_ASSET_HOST}/static/product/product_header_bg.svg`,
  spacing = 'my-20 md:my-52 px-4 gap-4',
}: HeroBottomProps) => {
  return (
    <Section spacing={spacing} className="relative">
      <div
        style={{
          background: `url(${bg})`,
          backgroundPosition: 'center',
          position: 'absolute',
          top: '-5%',
          left: '50%',
          transform: 'translateX(-50%)',
          height: '500px',
          width: '2028px',
          maxWidth: '100%',
          backgroundRepeat: 'no-repeat',
        }}
      ></div>

      {icon && (
        <Image
          alt="popsql logo"
          src={icon}
          width="135"
          height="160"
          loader={loaderProp}
          className="translate-y-6"
        />
      )}
      <Heading
        Tag="h2"
        size="lg"
        className={cn(
          'text-center max-w-[680px]',
          'motion-safe:opacity-0 motion-safe:animate-fadeInFromTop motion-safe:animation-delay-200',
        )}
      >
        {title}
      </Heading>

      <p
        className={cn(
          'max-w-3xl',
          'font-inter text-center font-medium sm:text-xl text-gray-600',
          'motion-safe:opacity-0 motion-safe:animate-fadeInFromTop motion-safe:animation-delay-300',
        )}
      >
        {description}
      </p>

      <CtaForm ctaText={ctaText} />
      {/* 
      {secondaryCtaText && (
        <Button variant="ghost" hasArrow>
          {secondaryCtaText}
        </Button>
      )} */}
    </Section>
  );
};
export default HeroBottom;
