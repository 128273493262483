import cn from 'classnames';
import Image from 'next/image';
import React from 'react';

import ConnectionsListCard from '@components/ConnectionsListCard';
import Heading from '@components/Heading';
import Section from '@components/Section';
import { loaderProp } from '@utils/random';

type GridCard = {
  image?: string;
  text: string;
  title: string;
};

type FiveCardProps = {
  card_1?: GridCard;
  card_2?: GridCard;
  card_3?: GridCard;
  card_4?: GridCard;
  card_5?: GridCard;
  description?: string;
  title?: string;
};

const FiveCardGridSection = ({
  title = 'Powerful across the stack',
  description = 'PopSQL enables your team to leverage existing infrastructure and tooling, elevating your workflow.',
  card_1 = {
    title: 'Do more with dbt Core',
    text: 'Connect to your dbt project and go from data engineering to analysis in seconds.',
    image: `${process.env.NEXT_PUBLIC_ASSET_HOST}/static/homepage/dbt_core.png`,
  },
  card_2 = {
    title: 'Works with any database',
    text: 'PopSQL supports every major database provider out there.',
  },
  card_3 = {
    title: 'Integrate with Slack',
    text: 'Send data to Slack so your team is always up-to-date.',
    image: `${process.env.NEXT_PUBLIC_ASSET_HOST}/static/homepage/integrate_with_slack.jpg`,
  },
  card_4 = {
    title: 'Useful APIs',
    text: 'API endpoints for query management and scheduling.',
    image: `${process.env.NEXT_PUBLIC_ASSET_HOST}/static/homepage/useful_apis.jpg`,
  },
  card_5 = {
    title: 'Connect to Git',
    text: 'Keep track of every change and review PRs with Git.',
    image: `${process.env.NEXT_PUBLIC_ASSET_HOST}/static/homepage/connect_to_git.png`,
  },
}: FiveCardProps) => {
  return (
    <Section className="pt-8 lg:pt-32">
      <div>
        <Heading
          Tag="h2"
          size="lg"
          className="mx-auto text-black text-center mb-4"
        >
          {title}
        </Heading>

        <p
          className={cn(
            'text-base sm:text-xl sm:max-w-2xl',
            'font-inter font-medium text-gray-600 dark:text-bliss text-center',
          )}
        >
          {description}
        </p>
      </div>

      <div className="mt-12 md:mt-24 w-full">
        <div className="grid md:grid-cols-2 gap-4 md:gap-8 max-w-[520px] md:max-w-full mx-auto">
          <div className="flex flex-col justify-between bg-[#22262A] rounded-[34px]">
            <div className="px-8 lg:px-12 pt-8 lg:pt-12">
              <Heading Tag="h3" size="sm" className="text-white">
                <>
                  {card_1.title}
                  <span className="relative left-1 -top-1 text-lg md:text-2xl">
                    ™
                  </span>
                </>
              </Heading>

              <p className="text-cobalt-600 text-base sm:text-xl font-inter font-medium mt-4 max-w-md">
                {card_1.text}
              </p>
            </div>
            <Image
              src={card_1.image}
              alt={`${card_1.title} illustration`}
              height="300"
              width="584"
              loader={loaderProp}
            />
          </div>

          <div className="bg-white border md:shadow-features border-gray-200 rounded-[34px] pb-2 max-w-[520px] md:max-w-full">
            <ConnectionsListCard
              heading={card_2.title}
              subHeading={card_2.text}
              overflow={false}
              customClasses="sm:my-0 sm:mt-4 sm:mb-8 px-0"
            />
          </div>
        </div>
      </div>

      <div
        className={`mt-1 md:mt-4 w-full grid ${
          card_3 ? 'md:grid-cols-3' : 'md:grid-cols-2'
        } gap-4 md:gap-6 max-w-[520px] md:max-w-full mx-auto`}
      >
        {card_3 && (
          <div className="bg-white border md:shadow-features border-gray-200 rounded-[34px] overflow-hidden">
            <Image
              src={card_3.image}
              alt={`${card_3.title} illustration`}
              height="240"
              width="584"
              loader={loaderProp}
            />
            <div className="px-7 lg:px-9 pb-8 lg:pb-8 pt-4">
              <p className="text-[#22262A] font-semibold font-inter text-xl">
                {card_3.title}
              </p>

              <p className="text-gray-600 text-base font-inter font-medium mt-4 max-w-md">
                {card_3.text}
              </p>
            </div>
          </div>
        )}
        <div className="bg-white border md:shadow-features border-gray-200 rounded-[34px] overflow-hidden">
          <Image
            src={card_4.image}
            alt={`${card_4.title} illustration`}
            height="260"
            width="584"
            loader={loaderProp}
          />
          <div className="px-7 lg:px-9 pb-8 lg:pb-8 pt-4">
            <p className="text-[#22262A] font-semibold font-inter text-xl">
              {card_4.title}
            </p>

            <p className="text-gray-600 text-base font-inter font-medium mt-4 max-w-md">
              {card_4.text}
            </p>
          </div>
        </div>
        <div className="bg-[#22262A] rounded-[34px] overflow-hidden">
          <Image
            src={card_5.image}
            alt={`${card_5.title} illustration`}
            height="240"
            width="584"
            loader={loaderProp}
          />
          <div className="px-7 lg:px-9 pb-8 lg:pb-8 pt-4">
            <p className="text-white font-semibold font-inter text-xl">
              {card_5.title}
            </p>

            <p className="text-white text-cobalt-600 text-base font-inter font-medium mt-4 max-w-md">
              {card_5.text}
            </p>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default FiveCardGridSection;
