import cn from 'classnames';
import React, { useCallback, useRef } from 'react';

import { submitForm } from '../../utils/hubspotClient';
import Button from '../Button';

const EmailInput = ({
  className,
  ctaText = 'Get started',
  placeholder = 'Enter your work email',
  noCC = false,
  onClickButton,
}: {
  className?: string;
  ctaText?: string;
  noCC?: boolean;
  onClickButton?: any;
  placeholder?: string;
}) => {
  const emailRef = useRef<HTMLInputElement>();
  const formRef = useRef<HTMLFormElement>();

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      await submitForm(
        process.env.NEXT_PUBLIC_HUBSPOT_PORTAL_ID,
        process.env.NEXT_PUBLIC_HUBSPOT_SIGNUP_FORM_ID,
        [
          {
            name: 'email',
            value: emailRef.current.value,
          },
        ],
      );
      formRef.current.submit();
    },
    [emailRef, formRef],
  );

  return (
    <div className="flex flex-col w-full items-center my-4 gap-2 sm:max-w-md">
      <form
        autoComplete="off"
        action={`${process.env.NEXT_PUBLIC_WEB_HOST}/users/check`}
        method="POST"
        className={cn(
          `w-full gap-3 sm:gap-0 flex flex-col sm:flex-row`,
          className,
        )}
        onSubmit={onSubmit}
        ref={formRef}
      >
        <input
          name="email"
          type="email"
          className="w-full placeholder-gray-900 shadow-header dark:shadow-none mr-3 px-4 border min-h-[40px] rounded-[12px] border-solid border-gray-200 text-sm sm:text-base focus:shadow-focus focus:outline-none font-medium font-inter dark:text-white dark:border-gray-700 dark:bg-[#2D353D]"
          placeholder={placeholder}
          ref={emailRef}
          required
        />
        <Button onClick={onClickButton}>{ctaText}</Button>
      </form>
      {noCC && (
        <span className="font-inter font-medium text-gray-400 text-2xs w-full text-center sm:pl-8">
          No credit card required
        </span>
      )}
    </div>
  );
};

export default EmailInput;
