import cn from 'classnames';
import React, { CSSProperties } from 'react';

const Heading = ({
  children,
  className,
  size = 'xl',
  Tag = 'h1',
  invert,
  id,
  customStyle,
}: {
  Tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'div';
  children: JSX.Element | string | JSX.Element[];
  className?: string;
  customStyle?: CSSProperties;
  id?: string;
  invert?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}) => {
  const sizes = {
    xs: 'text-xl sm:text-2xl',
    sm: 'text-2xl sm:text-3xl',
    md: 'text-3xl sm:text-5xl',
    lg: 'text-3xl sm:text-5xl',
    xlg: 'text-4xl sm:text-6xl',
    xl: 'text-4xl sm:text-6xl lg:text-7xl',
  };

  const classes = cn(
    sizes[size],
    invert ? 'dark:text-black text-white' : 'text-black dark:text-white',
    'font-display font-extrabold tracking-tightest',
    className,
  );

  return (
    <Tag id={id} className={classes} style={customStyle}>
      {children}
    </Tag>
  );
};

export default Heading;
