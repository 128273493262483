import React from 'react';

import styles from './slider.module.css';

const Slider = ({ duration, children }) => {
  const isF = typeof children === 'function';

  const child = (num) => (isF ? children(num) : children);

  return (
    <div className={styles['slider-container']}>
      <div
        className={styles['slider-content-wrapper']}
        style={{ '--duration': duration ? `${duration * 2}s` : '10s' }}
      >
        <div>{child(1)}</div>
        <div className={styles.duplicate}>{child(2)}</div>
      </div>
    </div>
  );
};

export default Slider;
