import cn from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import React, { useState } from 'react';

import { CONNECTIONS } from '../../data/connections';
import Heading from '../Heading';
import Section from '../Section';

const Figure = ({ img, hovering }) => (
  <div className="m-2 sm:m-3">
    <Link href={img.href} as={img.asPath}>
      <a className={cn('focus:outline-none')}>
        <div
          data-tip={img.title}
          className={cn(
            'hover:scale-125 transition group flex-col shrink-0 relative group',
            `h-10 w-10 sm:h-24 sm:w-24 bg-white rounded-[8px] sm:rounded-[16px] sm:rounded-xl shadow-header-menu flex items-center justify-center border-[0.5px]`,
            hovering && 'opacity-75 hover:opacity-100',
          )}
        >
          <figure
            title={img.title}
            className="h-8 w-8 sm:h-12 sm:w-12 flex relative items-center justify-center shrink-0"
          >
            <Image layout="fill" src={img.src} alt={img.title} />
            <figcaption className="absolute z-10 invisible font-inter group-hover:visible text-center min-w-[300px] -top-[54px]">
              <span className="text-gray-600 px-2 py-1 rounded-full text-2xs font-semibold border-[0.5px] bg-white">
                {img.title}
              </span>
            </figcaption>
          </figure>
        </div>
      </a>
    </Link>
  </div>
);

const ConnectionRoll = ({
  delay,
  alt = false,
  animate = true,
  hovering = false,
}) => {
  const list = alt
    ? CONNECTIONS.slice(0, 7)
    : CONNECTIONS.slice(7, CONNECTIONS.length);
  return (
    <div
      className={cn(
        'flex items-center justify-center mx-auto z-5 relative',
        animate && 'motion-safe:animate-marquee',
      )}
      style={{
        animationDelay: `${delay}ms`,
        animationDirection: alt ? 'forwards' : 'backwards',
        animationDuration: alt ? `28s` : `48s`,
        animationPlayState: hovering ? 'paused' : 'running',
      }}
    >
      {list.map((img, index) => (
        <Figure hovering={hovering} key={`${img.title}-${index}`} img={img} />
      ))}
    </div>
  );
};

type ConnectionListProps = {
  customClasses?: string;
  heading?: string;
  overflow?: boolean;
  subHeading?: string;
};

const ConnectionsListCard = ({
  overflow = true,
  heading = 'Works with any database',
  subHeading = 'PopSQL supports every major database provider out there.',
  customClasses,
}: ConnectionListProps) => {
  const [hovering, setHovering] = useState(false);

  return (
    <Section className={customClasses} customStyles={{ padding: '0' }}>
      <div className="flex flex-col justify-center items-center w-full overflow-hidden">
        <div className="w-full p-8 lg:p-12 pt-0 sm:pt-4 lg:pt-7 lg:pb-6">
          <Heading
            Tag="h3"
            size="sm"
            className={cn('mb-2 sm:mb-3')}
            customStyle={{ color: '#282828' }}
          >
            {heading}
          </Heading>

          <p
            className={cn(
              'font-inter font-medium text-base sm:text-xl text-gray-600',
            )}
          >
            {subHeading}
          </p>
        </div>
        <div
          className={cn(
            overflow && 'relative w-full pt-10 z-1',
            overflow &&
              'before:absolute before:bg-gradient-to-r before:from-white dark:before:from-cobalt-100',
            overflow &&
              'before:left-[0] before:top-0 before:w-[120px] before:h-full before:z-[1] before:will-change-[transform] before:transform-[rotate(-180deg)]',
            overflow &&
              'after:absolute after:bg-gradient-to-l after:from-white dark:after:from-cobalt-100',
            overflow &&
              'after:right-[0] after:top-0 after:w-[120px] after:h-full after:z-[1] after:will-change-[transform]',
          )}
          onMouseEnter={() => setHovering(!hovering)}
          onMouseLeave={() => setHovering(!hovering)}
        >
          <div className="flex items-center justify-center mx-auto max-w-[80vw] md:max-w-5xl">
            <ConnectionRoll hovering={hovering} delay={700} alt />
            <ConnectionRoll hovering={hovering} delay={700} alt />
            <ConnectionRoll hovering={hovering} delay={700} alt />
          </div>
          <div className="flex items-center justify-center mx-auto max-w-[80vw] md:max-w-5xl">
            <ConnectionRoll hovering={hovering} delay={0} />
            <ConnectionRoll hovering={hovering} delay={0} />
            <ConnectionRoll hovering={hovering} delay={0} />
          </div>
        </div>
      </div>
    </Section>
  );
};

export default ConnectionsListCard;
