import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import React from 'react';

const defaultMeta = {
  title: `PopSQL - Collaborative SQL editor for teams - Download our SQL tool
  for Mac, Windows, and Linux`,
  name: 'PopSQL - Collaborative SQL editor for teams',
  description: `PopSQL is a unified SQL collaboration workspace that connects everyone in the data analysis process so you can obtain better insights by asking the right questions, together.`,
  image: `${process.env.NEXT_PUBLIC_ASSET_HOST}/static/images/og/default.jpg`,
};

const defaultOpenGraph = {
  imageWidth: 1686,
  imageHeight: 882,
  url: `${process.env.NEXT_PUBLIC_WEB_HOST}`,
  siteName: 'PopSQL',
};

const CustomHead = ({ meta: propMeta, openGraph: propOpenGraph }) => {
  const meta = { ...defaultMeta, ...propMeta };
  const openGraph = { ...meta, ...defaultOpenGraph, ...propOpenGraph };
  const router = useRouter();

  return (
    <Head>
      <title>{meta.title}</title>
      {/* Search Engine */}
      <meta name="description" content={meta.description} />
      <meta name="image" content={meta.image} />

      {/* Schema.org for Google */}
      <meta itemProp="name" content={meta.title} />
      <meta itemProp="description" content={meta.description} />
      <meta itemProp="image" content={meta.image} />

      {/* Open Graph general (Facebook, Pinterest & Google+) */}
      <meta name="og:type" content="website" />
      <meta name="og:title" content={openGraph.title} />
      <meta name="og:site_name" content={openGraph.siteName} />
      <meta
        name="og:url"
        content={
          meta.url || `${process.env.NEXT_PUBLIC_WEB_HOST}${router.asPath}`
        }
      />
      <meta name="og:description" content={openGraph.description} />
      <meta name="og:locale" content="en_US" />

      <meta property="og:image" content={openGraph.image} />
      <meta property="og:image:secure_url" content={openGraph.image} />

      <meta name="og:type" content="article" />
      <meta property="og:image:width" content={openGraph.imageWidth} />
      <meta property="og:image:height" content={openGraph.imageHeight} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="popsql.com" />
      <meta property="twitter:url" content={openGraph.url} />
      <meta name="twitter:site" content="@popsql" />
      <meta name="twitter:creator" content="@popsql" />
      <meta name="twitter:image" content={openGraph.image} />
      <meta name="slack-app-id" content="ASRTJAU0J" />
      <meta property="fb:admins" content="505075733" />
      <meta
        name="facebook-domain-verification"
        content="6450x3zu78snl8fzu00nvibqw6anew"
      />
      <meta
        name="google-site-verification"
        content="EIU3pzSxqbWAozla8e5ZjPkJqT9yltw4ufMZuQP21bA"
      />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="theme-color" content="#ffffff" />

      {(meta.noIndex || meta.noFollow) && (
        <meta
          name="robots"
          content={`${meta.noIndex ? 'noindex,' : ''}${
            meta.noFollow ? 'nofollow' : ''
          }`}
        />
      )}

      <link
        rel="canonical"
        href={
          meta.url ||
          // Exclude any query parameters from the canonical url
          // exception: pagination parameters
          `${process.env.NEXT_PUBLIC_WEB_HOST}${router.asPath.split('?')[0]}${
            router.query.page ? `?page=${router.query.page}` : ''
          }`
        }
      />

      <Script strategy="afterInteractive">
        {`(function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;
      h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};
      (a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;
      })(window,document.documentElement,'async-hide','dataLayer',4000,
      {'${process.env.NEXT_PUBLIC_GOOGLE_OPTIMIZE_ID}':true});
      `}
      </Script>

      <Script strategy="afterInteractive">
        {`
          (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
          (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
          m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
          })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

          ga('create', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}', 'auto');
          ga('require', '${process.env.NEXT_PUBLIC_GOOGLE_OPTIMIZE_ID}');
        `}
      </Script>
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`${process.env.NEXT_PUBLIC_ASSET_HOST}/static/favicon/apple-touch-icon.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={`${process.env.NEXT_PUBLIC_ASSET_HOST}/static/favicon/favicon-32x32.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={`${process.env.NEXT_PUBLIC_ASSET_HOST}/static/favicon/favicon-16x16.png`}
      />
      <link
        rel="manifest"
        href={`${process.env.NEXT_PUBLIC_ASSET_HOST}/static/favicon/site.webmanifest`}
      />
      <link
        rel="mask-icon"
        href={`${process.env.NEXT_PUBLIC_ASSET_HOST}/static/favicon/safari-pinned-tab.svg`}
        color="#FFF"
      />
    </Head>
  );
};

export default CustomHead;
